/**
 * Created by Giannis Kallergis on 2018-12-30
 */

import React from "react";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Content from "../components/Content";
import { TextButton } from "../components/Button";
import AuthorHeader from "../components/AuthorHeader";

// noinspection JSUnusedGlobalSymbols
export default class ContactPage extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Wrapper>
          <SEO title={`Contact`} />
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader>
            <h1>Contact</h1>
            <p>Let's get in touch!</p>
            <form
              name="contact-form"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/success"
            >
              <p style={{ display: "none" }}>
                <label>
                  Don’t fill this out if you're human: <input name="bot-field" />
                </label>
              </p>
              <p>
                <label htmlFor="contact-name">
                  Name
                  <input name="name" id="contact-name" type="text" required />
                </label>
              </p>
              <p>
                <label htmlFor="contact-email">
                  E-Mail <input name="email" id="contact-email" type="email" required />
                </label>
              </p>
              <p>
                <label htmlFor="contact-message">
                  Your Message <textarea name="message" id="contact-message" required />
                </label>
              </p>
              <div data-netlify-recaptcha="true" />
              <p>
                <TextButton>Send</TextButton>
              </p>
              <input type="hidden" name="form-name" value="contact-form" />
            </form>
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}
